import React, { useState } from "react";
import Footer from "../components/home/Footer";
import BlockHero from "../components/home/Block_Hero";
import BlockContest from "../components/home/Block_Contest";
import Navigation from "../components/home/Navigation";
import FAQs from "../components/home/Block_FAQ";
import GameDay from "../components/home/Block_GameDay";
import BoostLineup from "../components/home/Block_BoostLineup";
import Discord from "../components/home/Block_Discord";
import About from "../components/home/Block_About";

// import IPage from "../interfaces/page";
import  '../common/styles/scss/SDStyles.scss';
import  '../common/styles/scss/SDColors.scss';
import DiscordPage from "./DiscordPage";
import Block_DontMissOut from "../components/home/Block_DontMissOut";

export interface IProps {}

const HomePage: React.FunctionComponent<IProps> = props =>  {

  const [style, setStyle] = useState({});

  function getStyleObject (sportId: string) {
    console.log("selectedSport: " + sportId);
    return {};
  }

        return (
            <div 
           >
              <div  className="stickyNav">
                <Navigation
                  onClickDiscord= {() => {}}
                  onClickUser= {() => {}}
                  onClickAbout= {() => {}}
                  onClickFaqs= {() => {}}
                  onClickWallet= {() => {}}
                  style = {{}}
                  />
              </div>
              <div>
            
          
              {/* <div className="dateLabel darkContainer light">Mar 2</div> */}
                <BlockHero
                  onClickWallet={()=>null}
                  style = {{}}/>
                  </div>

                <Block_DontMissOut
                  onClickDiscord={()=>{}}
                  onClickWallet={()=>{}}
                  onClickWhiteList={()=>{}}
                  style = {{}}
                  onRefresh = {()=>null}/> 
              
                <BlockContest
                  onClickWallet={()=>null}
                  percent={0}
                  style = {{}}/>
                          
                <GameDay
                  style = {{}}/>
              
                <BoostLineup
                  style = {{}}/>

                <FAQs
                  style = {{}}/>

                <Discord
                  onClickDiscord={()=>null}
                  style = {{}}/>

                <About
                  onClickSuperDraft={()=>null}
                  style = {{}}/>
                  
                <Footer/>
            </div>
          );
}

export default HomePage;