// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import SDButton from "../ui/SDButton"
import HeroImage from "../../assets/images/cardhero1.png"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import ReactPlayer from 'react-player';
import myVideo from '../../assets/videos/19_2.mp4';
import CountDownTimer from "../ui/CountDownTimer";
import { Dates } from '../../common/Constants';
import { useEffect, useRef } from "react";

 interface IProps {
    percent:number
 }

 Block_CollectTradeWin.defaultProps = {
     percent: 0
 }


export default function Block_CollectTradeWin(props: IProps) {
    const { style } = props

    const videoPlayerRef = useRef<ReactPlayer>(null);
    const videoIsShowing = useRef(true);
    const videoIsPlaying = useRef(false);
    const percentRef = useRef(0);
    if(percentRef.current < 1 || props.percent == 0) {
        percentRef.current = props.percent;
        videoIsShowing.current = percentRef.current >= 0;
        if(!videoIsPlaying.current && percentRef.current > .75) {
            videoIsPlaying.current = true;
        }
        else if(props.percent == 0){
            videoIsPlaying.current = false;
            if(videoPlayerRef.current !== null && videoPlayerRef.current.getDuration() !== null) {
                videoPlayerRef.current.seekTo(0);
            }
        }
    }

    // useEffect(() => {
        // if(videoPlayerRef.current !== null && videoPlayerRef.current.getDuration() !== null) {
            // console.log("currentTime: " + videoPlayerRef.current.getCurrentTime() + " : " + videoIsPlaying.current);
        //     console.log("ref: " + percentRef.current 
        //         + " : " + props.percent + " : " 
        //    + videoPlayerRef.current.getDuration() 
        //    + " : " + videoPlayerRef.current.getDuration() * props.percent);
        //     videoPlayerRef.current.seekTo(videoPlayerRef.current.getDuration() * percentRef.current);
        // }
    // });

    return (
        <motion.div style={style}  className="mainWrapper whiteContainer">
            {/* <div className="runningHead light">{percentRef.current + "%"}</div> */}
           <div className="innerWrapper column center">
                <div>
                    <motion.div className="subHeading dark marginSmall" style={{}}>
                       Collect , Trade and Win
                    </motion.div>
                    <div className="bodyTxt dark alignCenter">Connect and trade with other sports fans in our open marketplace. </div> 
                </div>
                <div>
                    {videoIsShowing.current && 
                        (<ReactPlayer
                        ref = {videoPlayerRef}
                        // onReady= {setPlayHead}
                        className='react-player fixed-bottom'
                        url= {myVideo}
                        width='100%'
                        height='100%'
                        loop = {false}
                        muted = {true}
                        playing = {videoIsPlaying.current}
                        // controls = {true}
                        config={{
                            mutedAutoplay: "all"
                        }}
                        />
                    )}
                </div>
            </div>
            {/* <div className="runningHead light">{percentRef.current + "%"}</div> */}
        </motion.div>
    )
}

