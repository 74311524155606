import React, { useEffect } from "react";
import Footer from "../components/home/Footer";
import Navigation from "../components/home/Navigation";
import IPage from "../interfaces/page";

export interface INftInventoryPageProps {}

const NftInventoryPage: React.FunctionComponent<INftInventoryPageProps> = props =>  {
    return <div style={{
        backgroundColor: '#FFF'
      }}>
        <div>
          <Navigation
            onClickDiscord= {() => {}}
            onClickUser= {() => {}}
            onClickAbout= {() => {}}
            onClickFaqs= {() => {}}
            onClickWallet= {() => {}}
            style = {{}}/>
        </div>
        <h3 style={content}>NftInventoryPage</h3>
        <hr style={{borderColor:"#FFF", width:"100%"}}/>
        <Footer></Footer>
    </div>
}

const content: React.CSSProperties = {
  width: "100%",
  height:400,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
}

export default NftInventoryPage;