import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/home/Footer";
import Navigation from "../components/home/Navigation";
import IPage from "../interfaces/page";
import {selectStyle, setPrimaryColor} from "../redux/store"
import { getRandomColor } from "../utils/ColorUtil";

export interface IProps {}

const DiscordPage: React.FunctionComponent<IProps> = props =>  {
  const style = useSelector(selectStyle);
  const dispatch = useDispatch();
  
  return <div style={{
    backgroundColor: style.primaryColor
  }}>
        <div>
          <Navigation
            onClickDiscord= {() => {}}
            onClickUser= {() => {}}
            onClickAbout= {() => {}}
            onClickFaqs= {() => {}}
            onClickWallet= {() => {}}
            style = {{}}/>
        </div>
        <h3 style={content}>DiscordPage</h3>
        <h1 style={content}onClick={()=>dispatch(setPrimaryColor(getRandomColor().hex))}>randomize bg color</h1>
        <hr style={{borderColor:"#FFF", width:"100%"}}/>
        <Footer></Footer>
    </div>
}


const content: React.CSSProperties = {
  width: "100%",
  height:400,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
}

export default DiscordPage;