// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import SDButton from "../ui/SDButton"
import HeroImage from "../../assets/images/cardhero1.png"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import ReactPlayer from 'react-player';
import myVideo from '../../assets/videos/cardhero1.mp4';

 interface IProps {
     onClickWallet:()=>void,
     style?:object
 }


export default function Block_Hero(props: IProps) {
    const { style } = props

    return (
        <motion.div style={{ ...style }}  className="mainWrapper blackContainer">
            
            <div className="innerWrapper row">
                <div className="heroVideo">
                    {/* <img src={HeroImage} className="heroImage1"/> */}
                    {/* {videoIsShowing.current &&  */}
                    {true && 
                    (<ReactPlayer
                        // ref = {videoPlayerRef}
                        // onReady= {setPlayHead}
                        url= {myVideo}
                        width='100%'
                        height='100%'
                        loop = {true}
                        muted = {true}
                        playing = {true}
                        controls = {false}
                        // config={{
                        //     mutedAutoplay: "all"
                        //   }}
                        />)}
                </div>
                <div>
                
                </div>
                <div className="vertCenter left">
                    <div className="heading light marginMedium">
                    The Next Generation of NFT Sport Collectables and Games
                    </div>
                    <div className="bodyTxt light">
                    Create (or Link) your wallet today, get on the whitelist to start collecting, trading, and competing with NFT’s for cash prizes in our exclusive new game mode: MATCH 23
                    </div>
                    <div className="left buttonGroup" >
                    <SDButton tint="#fff" textColor="#000" label="Create (or Link) a Wallet" onClick={props.onClickWallet}/>
                    </div>
                </div>
            </div>
            
        </motion.div>
    )
}

