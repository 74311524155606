import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"
import { useNavigate } from "react-router-dom"
import { Navigations } from "../../common/Constants"
import {ReactComponent as Discord} from "../../assets/svgs/logo_discord_2.svg"
import {ReactComponent as Twitter} from "../../assets/svgs/logo_twitter.svg"
import {ReactComponent as Instagram} from "../../assets/svgs/logo_instagram.svg"
import { Urls } from "../../common/Constants";
import MediaQuery from 'react-responsive'

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */

export interface IProps {
    style?:object

}

export default function Footer(props: IProps) {
    const {style} = props
    const [active, setActive] = useState(false)
    const navigate = useNavigate();

    return (
        <motion.div style={{ ...style }} className="footerContainer">
            {/* <div className="footerColumns">
                <ul style={footerTxt}>
                    <li style={colHeader}>MATCHUPS</li>
                    <li onClick={() => navigate(Navigations.HOME)}>Home</li>
                    <li onClick={() => navigate(Navigations.NFT_PACKS)}>NFT Packs</li>
                    <li onClick={() => navigate(Navigations.MARKET)}>Market</li>
                    <li onClick={() => navigate(Navigations.NFT_INVENTORY)}>NFT Inventory</li>
                </ul>
                <ul style={footerTxt}>
                    <li style={colHeader}>SUPPORT</li>
                    <li onClick={() => navigate(Navigations.HOW_TO_PLAY)}>How To Play</li>
                    <li onClick={() => navigate(Navigations.HELP)}>Help</li>
                    <li onClick={() => navigate(Navigations.STATUS)}>Status</li>
                    <li onClick={() => navigate(Navigations.DISCORD)}>Discord</li>
                </ul>
                <ul style={footerTxt}>
                    <li style={colHeader}>COMPANY</li>
                    <li onClick={() => navigate(Navigations.ABOUT)}>About</li>
                    <li onClick={() => navigate(Navigations.BLOG)}>Blog</li>
                    <li onClick={() => navigate(Navigations.PRESS)}>Press</li>
                    <li onClick={() => navigate(Navigations.PARTNERS)}>Partners</li>
                </ul>
                <ul style={footerTxt}>
                    <li style={colHeader}>LEGAL</li>
                    <li onClick={() => navigate(Navigations.PRIVACY)}>Privacy</li>
                    <li onClick={() => navigate(Navigations.TERMS)}>Terms</li>
                </ul>
            </div> */}
            <hr style={divider} />
            <div className="footerIcons">
                <div style={footerTxt} onClick={()=> navigate(Navigations.HOME)}>
                    © 2022 Superdraft, Inc. All Rights reserved
                </div>
                <MediaQuery minWidth={768}>
                {(matches: any) => matches ? (
                        <div className="iconGroup">
                            <Twitter className="pointer" style={{height:"32px", width:"32px"}} onClick={()=> window.open(Urls.TWITTER)} />
                            <Instagram className="pointer" style={{height:"32px", width:"32px"}} onClick={()=> window.open(Urls.INSTAGRAM)} />
                            <Discord className="pointer" style={{height:"32px", width:"32px"}} onClick={()=> window.open(Urls.DISCORD)} />
                        </div>
                    ):(<div></div>)}
                </MediaQuery>
                
            </div>
        </motion.div>
    )
}


const footerTxt: React.CSSProperties = {
    listStyleType: "none",
    width: "100%",
    lineHeight: 2,
    fontSize: 16,
    color: "#aaa",
    fontWeight: 500,
}

const colHeader: React.CSSProperties = {
    opacity: 1,
    color: "#fff",
    fontWeight: 700,
}

const divider: React.CSSProperties = {
    width: "100%",
    borderColor: "#6B6B6B",
    marginBottom:24,
}