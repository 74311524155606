// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import SDButton from "../ui/SDButton"
import {ReactComponent as DiscordLogo} from "../../assets/svgs/logo_discord.svg"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';

interface IProps {
    onClickDiscord: () => void;
    style?: object
}


export default function Block2(props: IProps) {
    const { style } = props

    return (
        <motion.div style={style }  className="mainWrapper darkContainer">
           <div className="innerWrapper column left">
            <div style={column}>
                <div className="runningHead light">Stay in the loop</div>
                <div className="heading light">
                Join our Discord
                </div>
            </div>
            
            <div className="discordRow">
                <div className="light fontStyle2">
                    <p>As the SuperDraft MatchUps community grows — we love for you to join in the fun on Discord.</p>
                    <p>Keep tabs on the progress, get all the latest news, ask questions and connect with other players/collectors along with the SuperDraft Team behind MatchUps.</p>
                    <p>There are opportunities to get on the whitelist, win NFTs and other prizes as we ramp up to the launch of the game in September.</p>
                    <p style={buttonGroup} >
                    <SDButton tint="#fff" textColor="#000" label="Join Our Discord" onClick={props.onClickDiscord}/>
                </p>
                </div>
                <DiscordLogo className="discordHero" />
            </div>
         
            </div>
        </motion.div>
    )
}

Block2.defaultProps = {
    tint: "#09F",
}

const column = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignContent:"left",
    alignItems: "left",
    gap:32,
    overflow: "hidden",
   
}
const buttonGroup = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    gap:24,
    overflow: "hidden",
    width:"auto",

}


