export const Navigations = {
    SPLASH: "/",
    HOME: "/home",
    NFT_PACKS: "/nftPacks",
    NFT_INVENTORY: "/nftInventory",
    HOW_TO_PLAY: "/howToPlay",
    HELP: "/help",
    ABOUT: "/about",
    STATUS: "/status",
    PRESS: "/press",
    PARTNERS: "/partners",
    PRIVACY: "/privacy",
    TERMS: "/terms",
    DISCORD: "/discord",
    MARKET: "/market",
    BLOG: "/blog",
    //
    OPEN_PACK: "/openPack",
};

export const Urls = {
    DISCORD : "https://discord.gg/eBGaptZm",
    INSTAGRAM : "https://www.instagram.com/challenge/?next=/superdraftdfs/",
    ATOMIC_HUB : "https://wax.atomichub.io/",
    TWITTER : "https://twitter.com/superdraftdfs",
    WAX : "https://metamask.io/",
    SUPER_DRAFT : "https://superdraft.io/"
};

export const Dates = {
    NFT_SPLASH_LAUNCH : "Apr 15, 2022 00:00:00",
    // NFT_SPLASH_LAUNCH : "Mar 29, 2022 17:07:00",
    
};