import styled from "styled-components";

export const ModalContainer = styled.div`
    background-color: #DEDEDE;
    box-shadow: 0 0 32px rgba(0,0,0,.75);
    display: flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    border-radius: 7px;
    padding: 0px;
    width: 100%;
    height:100%;
    margin:40px;
    font-size 26px;
`

export const Header = styled.h3`
    color: white;
    font-size: 35px;
    line-height: 1em;
    font-weight: 300;
    margin: 5px 0 10px;
    text-align: center;
`