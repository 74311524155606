// export function blend_colors(color1:string, color2:string, percentage:number) {
//   var rgbA = hextoArray(color1);
//   var rgbB = hextoArray(color2);
//   var r = colorChannelMixer(rgbA[0], rgbB[0], percentage);
//   var g = colorChannelMixer(rgbA[1], rgbB[1], percentage);
//   var b = colorChannelMixer(rgbA[2], rgbB[2], percentage);
//   return 'rgb(' + r + ',' + g + ',' + b + ')';
// }

// function colorChannelMixer(colorChannelA:number, colorChannelB:number, amountToMix:number) {
//   var channelA = colorChannelA * amountToMix;
//   var channelB = colorChannelB * (1 - amountToMix);
//   return parseInt(channelA + channelB);
// }

export interface IColor {
    hex:string,
    rgb:string
}

export function getRandomColor():IColor {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  var theColor = 'rgb(' + r + ',' + g + ',' + b + ')';
  var iColor:IColor = {hex:"", rgb:""};
  iColor.hex = rgbToHex(r, g, b);
  iColor.rgb = theColor;
  return iColor;
}

// export function darkenColor(hex, lum) {
//   // validate hex string
//   hex = String(hex).replace(/[^0-9a-f]/gi, '');
//   if (hex.length < 6) {
//     hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//   }
//   lum = lum || 0;

//   // convert to decimal and change luminosity
//   var rgb = '#',
//     c,
//     i;
//   for (i = 0; i < 3; i++) {
//     c = parseInt(hex.substr(i * 2, 2), 16);
//     c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
//     rgb += ('00' + c).substr(c.length);
//   }

//   return rgb;
// }

function componentToHex(c:number) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r:number, g:number, b:number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// export function sportColor(sportId) {
//   if (Globals.sportColors[sportId]) {
//     return Globals.sportColors[sportId];
//   } else {
//     return Globals.COLOR.defaultSportColor;
//   }
// }

// export function hexToRgbA(hex, alpha) {
//   if (!hex || [4, 7].indexOf(hex.length) === -1) {
//     return; // throw new Error('Bad Hex');
//   }

//   hex = hex.substr(1);
//   // if shortcuts (#F00) -> set to normal (#FF0000)
//   if (hex.length === 3) {
//     hex = hex
//       .split('')
//       .map(function (el) {
//         return el + el + '';
//       })
//       .join('');
//   }

//   var r = parseInt(hex.slice(0, 2), 16),
//     g = parseInt(hex.slice(2, 4), 16),
//     b = parseInt(hex.slice(4, 6), 16);

//   return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
// }

// export function hextoArray(hex) {
//   if (!hex || [4, 7].indexOf(hex.length) === -1) {
//     return; // throw new Error('Bad Hex');
//   }

//   hex = hex.substr(1);
//   // if shortcuts (#F00) -> set to normal (#FF0000)
//   if (hex.length === 3) {
//     hex = hex
//       .split('')
//       .map(function (el) {
//         return el + el + '';
//       })
//       .join('');
//   }

//   var r = parseInt(hex.slice(0, 2), 16),
//     g = parseInt(hex.slice(2, 4), 16),
//     b = parseInt(hex.slice(4, 6), 16);

//   return [r, g, b];
// }

