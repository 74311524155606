import React, { useState, useRef, useEffect, useLayoutEffect} from "react";
import useGeolocation from 'react-hook-geolocation';
import Footer from "../components/home/Footer";
import BlockHero from "../components/home/Block_Hero";
import BlockContest from "../components/home/Block_Contest";
import BlockCollectTradeWin from "../components/home/Block_CollectTradeWin";
import BlockTest1 from "../components/home/Block_Test_1";
import BlockTest2 from "../components/home/Block_Test_2";
import BlockTest3 from "../components/home/Block_Test_3";
import BlockGeoLoc from "../components/home/BlockGeoLoc";
import NavigationSplash from "../components/home/Navigation_Splash";
import FAQs from "../components/home/Block_FAQ";
import GameDay from "../components/home/Block_GameDay";
import About from "../components/home/Block_About";
import { Dates, Urls } from "../common/Constants";
import Block_DontMissOut from "../components/home/Block_DontMissOut";
import Block_Mint from "../components/home/Block_Mint";
import Block_Timeline from "../components/home/Block_Timeline";

// import IPage from "../interfaces/page";
import  '../common/styles/scss/SDStyles.scss';
import  '../common/styles/scss/SDColors.scss';
import Modal from "../components/ui/modal/ModaL";
import WhiteList from "../components/home/WhiteList";
import { isDateExpired } from "../utils/DateUtil";
import Block_CollectTradeWin from "../components/home/Block_CollectTradeWin";
import { ITeam, getRandomTeam } from "../common/sports/nfl";
import nflTeams from '../assets/data/nflTeams.json';
import { getClosestGeoLocation } from '../utils/GeoUtil';
import {ILocation} from '../common/sports/Locations'

export interface IProps {}

const SplashPage: React.FunctionComponent<IProps> = props =>  {

  console.log("SplashPage.render---------");
  const latRef = useRef(0);
  const lonRef = useRef(0);
  const geoLocAttempted = useRef(false);
  const isRandomTeamRef = useRef(false);
  const [localTeam, setLocalTeam] = useState<ITeam|undefined>(undefined);
  
  if(geoLocAttempted.current == false) {
    console.log("geoLocAttempt XXXXXXXXXXXX: " + geoLocAttempted.current);
    geoLocAttempted.current = true;
    const successHandler = (position:GeolocationPosition) => {
      console.log("success coords: " + position.coords.latitude + ": "+ position.coords.longitude + " : "+ localTeam);
      if(localTeam == undefined){
        latRef.current = position.coords.latitude;
        lonRef.current = position.coords.longitude;
        const closestLocation:ILocation = getClosestGeoLocation(latRef.current, lonRef.current);
        const teams:ITeam[] = nflTeams;
        const iTeams:ITeam[] = teams;
        const closestTeam = iTeams.find(
        (team, index, teams) => {
          return closestLocation.nflTeams.find((locTeamId, i, teamIds) => {
            if(team.id === locTeamId) {
              return team;
            }
          }
          )});
        if(closestTeam != undefined || closestTeam != null){
          setLocalTeam(closestTeam);
        }
        else {
          isRandomTeamRef.current = true;
          setLocalTeam(getRandomTeam);
        }
      }
    }
    const errorHandler = (error:{message:string}) => {
      console.error("nav.geoLoc.error: " + error.message);
      if(localTeam == undefined){
        geoLocAttempted.current = true;
        isRandomTeamRef.current = true;
        setLocalTeam(getRandomTeam);
      }
    }
    navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    
  }
  // const geolocation = useGeolocation();
  // const [geoLocatedTeam, setGeoLocatedTeam] = useState<ITeam>();
  // console.log("geoLocation.error: " + geolocation.error);
  // if(!geolocation.error && !geoPrevSuccess.current) {
  //   console.log("calcing location using coords");
  //   geoPrevSuccess.current = true;
  //   localTeamRef.current = getRandomTeam();
  //   const teams:ITeam[] = nflTeams;
  //   const getDefaultLocation = () => {
  //     return {
  //       id:"0",
  //       name:"",
  //       coordinates:[],
  //       nflTeams:[]
  //     }
  //   }
  //   const closestLocation:ILocation = !geolocation.error ? getClosestGeoLocation(geolocation.latitude, geolocation.longitude) : getDefaultLocation();
  //   if(!geolocation.error) {
  //     latRef.current = geolocation.latitude;
  //     lonRef.current = geolocation.longitude;
  //     console.log("geoLocation coords: " + geolocation.latitude + " : " + geolocation.longitude);
  //     console.log("geoLocation ref coords: " + latRef.current + " : " + lonRef.current);
  //   }
  //   const iTeams:ITeam[] = teams;
  //   const closestTeam = iTeams.find(
  //     (team, index, teams) => {
  //       return closestLocation.nflTeams.find((locTeamId, i, teamIds) => {
  //         if(team.id === locTeamId) {
  //           localTeamRef.current = team;
  //           return team;
  //         }
  //       }
  //       )});
  //   console.log("SplashPage closeTeam: " + closestTeam?.city);
    // setGeoLocatedTeam(closestTeam);
    
  // }
    
  const scrollToRef = (ref:React.MutableRefObject<HTMLDivElement | null>) => {
    if(ref.current != null ) {
      // window.scrollTo(0, ref.current.offsetTop);
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.log("ref is null");
    }  
    
  };
  // const test1Ref = useRef<HTMLDivElement | null>(null);
  // const test2Ref = useRef<HTMLDivElement | null>(null);
  // const test3Ref = useRef<HTMLDivElement | null>(null);
  const block1Ref = useRef<HTMLDivElement | null>(null);
  const collectTradeWinRef = useRef<HTMLDivElement | null>(null);
  const blockContestRef = useRef<HTMLDivElement | null>(null);
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const faqsRef = useRef<HTMLDivElement | null>(null);
  //easter eggs - dev mode
  const easterEggActivationCnt = useRef<number>(0);
  const [isEasterEggActive, setIsEasterEggActive] = useState(false);
  const onEasterEggActivationAttempt = () => {
    if(!isEasterEggActive) {
      easterEggActivationCnt.current++;
      if(easterEggActivationCnt.current >= 10) {
        setIsEasterEggActive(true);
      }
    }
    else {
      easterEggActivationCnt.current = 0;
      setIsEasterEggActive(false);
    }
  }

  const [isWhiteListOpen, setIsWhiteListOpen] = useState(false);
  const gotoWhiteList = () => {
    console.log("whiteList"); 
    setIsWhiteListOpen(true);
  }

  if(isWhiteListOpen) {
    document.body.style.overflow = "hidden"; 
  }
  else {
    document.body.style.overflow = "unset"; 
  }

  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  
  }, []);

  // const testTopPos = testRef.current !== null ? testRef.current.getBoundingClientRect().top : 0;
  // const [test1Percent, setTest1Percent] = useState(0);
  // const [test2Percent, setTest2Percent] = useState(0);
  // const [test3Percent, setTest3Percent] = useState(0);
  const [collectTradeWinPercent, setCollectTradeWinPercent] = useState(0);
  const [blockContestPercent, setBlockContestPercent] = useState(0);

  const onScroll = () => {
    // if(test1Ref.current !== null) {
    //   setTest1Percent(calcPercentInCenter(test1Ref.current));
    // }
    // if(test2Ref.current !== null) {
    //   setTest2Percent(calcPercentInCenter(test2Ref.current));
    // }  
    // if(test3Ref.current !== null) {
    //   setTest3Percent(calcPercentInCenter(test3Ref.current));
    // }
    if(collectTradeWinRef.current !== null) {
      setCollectTradeWinPercent(calcPercentInCenter(collectTradeWinRef.current));
    } 
    if(blockContestRef.current !== null) {
      setBlockContestPercent(calcPercentInCenter(blockContestRef.current));
    }   
  }

  //call to refresh of this component
  const [random, setRandom] = useState(0);
  const refresh = () => {
    setRandom(Math.random());
  }

  // Use requestAnimationFrame for smooth playback
  function scrollPlay(){  
    var frameNumber  = window.pageYOffset/500; //playbackConst;
    // vid.currentTime  = frameNumber;
    // console.log("scrollPlay: " + frameNumber);
    requestAnimationFrame(scrollPlay);
  }
  // requestAnimationFrame(scrollPlay);

        return (
            <div
           >
              <div  className="stickyNav">
                <NavigationSplash
                  onClickDiscord = {()=>{ gotoDiscord()}}
                  onClickFaqs= {()=>{ scrollToRef(faqsRef)}}
                  onClickAbout= {()=>{ scrollToRef(aboutRef)}}
                  onClickUser= {()=>{ gotoUser()}}
                  onClickWallet= {()=>{ gotoWallet()}}
                  style = {{opacity:.99}}
                  onEasterEgg={onEasterEggActivationAttempt}
                  />
              </div>
              {isEasterEggActive && 
                <div>
                  <BlockGeoLoc lat={latRef.current} lon={lonRef.current} team={localTeam ? localTeam : getRandomTeam()} isRandomTeam={isRandomTeamRef.current}/>
                </div>
              }

              {localTeam != undefined && <div ref = {block1Ref}>
              {/* <div className="dateLabel darkContainer light">Mar 2</div> */}
                <BlockHero
                  onClickWallet={gotoWallet}
                  style = {{}}/>
              </div>}
              {!isDateExpired(Dates.NFT_SPLASH_LAUNCH) && (
                <Block_DontMissOut
                onClickDiscord={gotoDiscord}
                onClickWallet={gotoWallet}
                onClickWhiteList={gotoWhiteList}
                style = {{}}
                onRefresh = {refresh}/> 
              )}
              
              {isDateExpired(Dates.NFT_SPLASH_LAUNCH) && (
                <Block_Mint
                onClickDiscord={gotoDiscord}
                onClickWallet={gotoWallet}
                onClickWhiteList={gotoWhiteList}
                style = {{}}/> 
              )}  

              <div ref = {blockContestRef}>
                <BlockContest
                  onClickWallet={gotoWallet}
                  percent={blockContestPercent}
                  style = {{}}/>
              </div>

                <div ref = {collectTradeWinRef}>
                  <BlockCollectTradeWin percent={collectTradeWinPercent} />
                </div>

                {/* <div ref = {test1Ref}>
                  <BlockTest1 percent={test1Percent} />
                </div> */}
                  
               <Block_Timeline style = {{}}/>
                
                <div ref = {faqsRef}>
                  <FAQs
                    style = {{}}/>
                </div>
               


                <div ref = {aboutRef}>
                <About
                  onClickSuperDraft={gotoSuperDraft}
                  style = {{}}/>
                </div>


               

              <Footer/>
              
              {isWhiteListOpen && (<Modal 
                    onClose={()=>{
                      document.body.style.overflow = "unset";
                      setIsWhiteListOpen(!isWhiteListOpen);
                    }}
                  >
                    <WhiteList onClose={()=>{
                      document.body.style.overflow = "unset";
                      setIsWhiteListOpen(!isWhiteListOpen);
                    }}/>
              </Modal>)}

            </div>
          );
}

const gotoDiscord = () => {
  window.open(Urls.DISCORD);
}

const gotoUser = () => {
  console.log("user action");
}

const gotoWallet = () => {
  console.log("wallet action");
}

const gotoAtomicHiub = () => {
  window.open(Urls.ATOMIC_HUB);
}

const gotoSuperDraft = () => {
  window.open(Urls.SUPER_DRAFT);
}

const calcPercentInView = (element: HTMLDivElement) => {
  let percent = 0;


  return 0;
}

const calcPercentInCenter = (element: HTMLDivElement) => {
  let percent = 0;
  const ctrPoint = window.innerHeight * .5;
  const rectPoint = element.getBoundingClientRect().top + (element.offsetHeight) * .5;
  const y = element.getBoundingClientRect().top;
  // console.log("window.innerHeight: " + window.innerHeight);
  // console.log("element.y: " + y);
  //if off screen
  if(y >= window.innerHeight || (y + element.offsetHeight) < 0) {
    // console.log("off screen")
    // console.log("percent: " + percent);
    return 0;
  }

  const maxDistance = window.innerHeight * .5 + element.offsetHeight * .5;
  const currentDistance = rectPoint - ctrPoint;

  // if(rectPoint >= ctrPoint) {
    percent = 1 - Math.abs(currentDistance / maxDistance);
    percent = Math.round((percent + Number.EPSILON) * 100) / 100;
  // }
  // else {
  //   percent = (currentDistance / maxDistance);
  // }
    
  // console.log("percent: "+ percent);
  return percent;
}

const calcScrollPositonPercent = (element: HTMLDivElement) => {
  let scrollY = element.getBoundingClientRect().top;
  const y = element.offsetTop;
  const h = element.offsetHeight;
  const scrollYBottom = scrollY + h;
  const wHeight = window.innerHeight;
  let percent = 0;
  console.log(scrollY + " : "+ wHeight);
  if(scrollY < wHeight) {
    console.log("< wHeight: " + scrollY);
    const heightInView = wHeight - scrollY;
    if(scrollYBottom <= wHeight) {
      //completely in view
    }

    scrollY = Math.max(0, scrollY);
    percent = heightInView / h;
    percent = Math.round((percent + Number.EPSILON) * 100) / 100;
  }
  else {
    percent = 100;
  }

  return percent;
}

export default SplashPage;