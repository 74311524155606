
import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"
import {ReactComponent as SDLogo} from "../../assets/svgs/sd_matchups.svg"
import { useNavigate } from "react-router-dom";
import { Navigations } from "../../common/Constants"
import { FaWallet, FaUserCircle, FaHamburger, FaBars } from "react-icons/fa"
import {ReactComponent as DiscordLogo} from "../../assets/svgs/logo_discord_2.svg"
import MediaQuery from "react-responsive";
import NavModalMenu from "../../components/ui/NavModalMenu";

 interface IProps {
    onClickDiscord: () => void;
    onClickAbout: () => void;
    onClickFaqs: () => void;
    onClickUser: () => void;
    onClickWallet: () => void;
    style?: object,
 }

export default function Navigation(props : IProps) {
    const { style } = props

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate();

    const onClickBars = ()=> {
        setIsMenuOpen(!isMenuOpen);
        console.log("isMenuOpen: "+ isMenuOpen);
    }

    if(isMenuOpen) {
        document.body.style.overflow = "hidden";
    }
    else {
        document.body.style.overflow = "unset";
    }
    
    return (
        <motion.div style={{ ...style, ...containerStyle, ...fontStyleNav }}>
            
            <div className="navLogo">
                <SDLogo style={logo} onClick={()=>navigate(Navigations.SPLASH)}/>
            </div>
            
            <MediaQuery minWidth={768}>
                {(matches: any) => matches ? (

                    <ul className="navGroup">
                        {/* <li>
                            <a onClick={() => props.onClickFaqs()}>
                                FAQs
                            </a>
                        </li>
                        <li>
                            <a onClick={() => props.onClickAbout()}>
                                About
                            </a>
                        </li> */}
                        <li>
                            <a onClick={() => props.onClickDiscord()}>
                                <span className="buttonIcon"> <DiscordLogo style={{height:"24px", width:"24px"}}/> Join Our Discord</span>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => props.onClickUser()}>
                            <FaUserCircle style={{height:"24px", width:"24px"}} />
                            </a>
                        </li>
                        <li>
                            <a onClick={() => props.onClickWallet()}>
                            <FaWallet style={{height:"24px", width:"24px"}} />
                            </a>
                        </li>
                    </ul>    
                ): (
                    <div style={{width:"100%", padding:2, display:"flex", flexDirection:"row-reverse", alignItems:"right"}}>
                        <ul className="navGroup">
                            <li>
                                <a onClick={onClickBars}>
                                <FaBars style={{height:"24px", width:"24px"}} />
                                </a>
                            </li>
                        </ul>
                        {isMenuOpen && (<NavModalMenu 
                            onClose={()=>setIsMenuOpen(false)}
                            onClickAbout = {()=>{setIsMenuOpen(false); props.onClickAbout();}}
                            onClickFaqs = {()=>{setIsMenuOpen(false); props.onClickFaqs();}}
                            onClickDiscord = {()=>{setIsMenuOpen(false); props.onClickDiscord();}}
                            onClickUser = {()=>{setIsMenuOpen(false); props.onClickUser();}}
                            onClickWallet = {()=>{setIsMenuOpen(false); props.onClickWallet();}}
                        />)}
                    </div>
                )}

            </MediaQuery>
            
        </motion.div>
    )
}

Navigation.defaultProps = {
    tint: "#09F",
}

const containerStyle: React.CSSProperties = {
    display:"flex",
    background: "white",
    padding: "16px 32px",
    height: 40,
    overflow: "hidden",
}
const logo: React.CSSProperties = {
    height: 40,
    margin: "0",
    padding: "0",
}

const fontStyleNav: React.CSSProperties = {
    textDecoration: "none",
    fontWeight: 500,
    fontFamily: '"Inter-Medium", "Inter", sans-serif',
    color: "#999",
    fontSize: "17px",
}