import { useState } from "react"
import { motion } from "framer-motion"
import { SlowBuffer } from "buffer"


interface IProps { 
    style?:object
    tint:string
    textColor:string
    label:string,
    onClick?:()=>void
}

SDButton.defaultProps = {
    tint: "#fff",
    textColor: "#000",
    label: "Button Label",
}
export default function SDButton(props : IProps) {
    const { style, tint,textColor, label } = props
   

    return (
        <motion.div style={{ ...style, ...containerStyle }} onClick={props.onClick}>
            <motion.div
                style={{
                    backgroundColor: tint,
                    color: textColor,
                }}
                className="primaryButton pointer"
            >
                {label}
            </motion.div>
        </motion.div>
    )
}

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
