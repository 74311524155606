import { useEffect, useRef } from "react";
import { motion } from "framer-motion"
import SDButton from "../ui/SDButton"
import Booster from "../../assets/images/booster_group.png"
import FoundersGroup from "../../assets/images/foundersGroup.png"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import video from '../../assets/videos/boosterReveal2.mp4';
import ReactPlayer from 'react-player';

interface IProps {
    onClickWallet: ()=>void;
    percent:number;
    style?:object;
}

export default function BlockContest(props: IProps) {
    const { style } = props

    const videoPlayerRef = useRef<ReactPlayer>(null);
    const videoIsShowing = useRef(true);
    const videoIsPlaying = useRef(false);
    const percentRef = useRef(0);
    if(percentRef.current < 1 || props.percent == 0) {
        percentRef.current = props.percent;
        videoIsShowing.current = percentRef.current >= 0;
        if(!videoIsPlaying.current && percentRef.current > .75) {
            videoIsPlaying.current = true;
        }
        else if(props.percent == 0){
            videoIsPlaying.current = false;
            if(videoPlayerRef.current !== null && videoPlayerRef.current.getDuration() !== null) {
                videoPlayerRef.current.seekTo(0);
            }
        }
    }

    return (
        <motion.div style={style }  className="mainWrapper blackContainer">
           <div className="innerWrapper column">
               <div className="contestBlock">
               <div className="heroVideo2">
                    {/* <img src={Booster} className="heroImage2 contestImg" /> */}
                    {videoIsShowing.current && 
                        (<ReactPlayer
                        ref = {videoPlayerRef}
                        // onReady= {setPlayHead}
                        className='react-player fixed-bottom'
                        url= {video}
                        width='100%'
                        height='100%'
                        loop = {false}
                        muted = {true}
                        playing = {videoIsPlaying.current}
                        // controls = {true}
                        // config={{
                        //     mutedAutoplay: "all"
                        // }}
                        />
                    )}
                    </div>
                    <div className="light bodyTxt textBlock">
                        <div className="subHeading highlightGold marginMedium">
                        Win $100,000 in Crypto.
                        </div>
                        <div>
                            <p>Be one of the first to mint an exclusive 'Founders Launch Edition' card pack. </p>
                            <p className="strong">What you'll get</p>
                            <ul className="listStyle"><li>5 football player NTF cards</li>
                                <li>SuperDraft Match 2022 Season Pass with access to special contests and events throughout the year</li>
                                </ul>
                            <p><span className="strong">EXCLUSIVE BONUS: </span>10 card packs contain a Founders VIP Game Entry where you can compete for a chance to win $100,000 in NFTs & Crypto.</p>
                        </div>
                        <div className="buttonGroup centerButton" >
                            <SDButton tint="#fff" textColor="#000" label="Create (or Link) a Wallet"  onClick={props.onClickWallet}/>
                        </div>
                    </div>
                </div>
           
            </div>
        </motion.div>
    )
}

BlockContest.defaultProps = {
    tint: "#09F",
}

