// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import SDButton from "../ui/SDButton"
import {ReactComponent as WaxLogo} from "../../assets/svgs/logo_wax_dark.svg"
import {ReactComponent as AtomicLogo} from "../../assets/svgs/logo_atomic_badge_dark.svg"
import {ReactComponent as SDBadge} from "../../assets/svgs/logo_superdraft_badge_dark.svg"
import CountDownTimer from "../ui/CountDownTimer";
import { Dates } from "../../common/Constants";
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';

interface IProps {
    onClickDiscord:()=>void,
    onClickWallet:()=>void,
    onClickWhiteList:()=>void,
    style?: object
}


export default function Block_Mint(props: IProps) {
    const { style } = props

    return (
        <motion.div style={style }  className="mainWrapper lightContainer">
            
           <div className="innerWrapper column left">
               <div className="innerWrapper row innerBlock marginMedium">
                    <div className="textBlock">
                            {/* <div className="runningHead dark alignLeft">
                                <CountDownTimer targetDate={Dates.NFT_SPLASH_LAUNCH}/>
                                </div> */}
                            <div className="heading dark marginMedium">
                                Mint
                            </div>
                            <div className="bodyTxt dark marginMedium">
                                Mint today.
                            </div>
                            <div style={buttonGroup} >
                                <SDButton tint="#000" textColor="#fff" label="Create Wallet"  onClick={props.onClickWhiteList}/>
                            </div>
                        </div>
                        <div>    
                        <div className="videoBlock" />
                    </div>
                </div>
                
                <div className="badgeGroup row bodyTxt dark">
                    <div className="badge column">
                        <AtomicLogo className="waxHero" onClick={props.onClickWallet}/>
                        <span className="subHeadline strong">Create WAX wallet</span>
                        This is where you will purchase and collect your NFT’s
                        </div>
                        <div className="badge column">
                        <WaxLogo className="atomicHero" onClick={props.onClickDiscord}/>
                        <span className="subHeadline strong">Join our Discord</span>
                        Get access to all of the lastest news and exclusive offers
                        </div>
                        <div className="badge column">
                        <SDBadge className="sdBadgeHero" onClick={props.onClickWhiteList}/>
                        <span className="subHeadline strong">Get on the whitelist</span>
                        Be the first to access our NFT monthly releases
                    </div>
                </div>
          
         
            </div>
        </motion.div>
    )
}

Block_Mint.defaultProps = {
    tint: "#09F",
}

const column: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: 16,
}

const block1: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 16,
    width: "100%",
    maxWidth: "1400px",
}

const buttonGroup = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    gap:24,
    overflow: "hidden",
    width:"auto",

}


