import React, { useState, useRef, useEffect } from "react"

import { FaChevronDown } from "react-icons/fa"

import  '../../common/styles/scss/FAQ.scss';


interface IProps { 
    style?:object
    answer:string
    question:string
    index:number
}

FAQAccordian.defaultProps = {
    index: 1,
    question: "What is the Whitelist?",
    answer:
        "Quisque imperdiet risus at dolor aliquet, vitae venenatis ex pulvinar. Ut ac scelerisque lacus. Sed ullamcorper velit sed lectus sodales viverra. Integer vel venenatis velit. Duis feugiat nunc et scelerisque euismod. Quisque tempor id eros ultrices pretium.",
}

export default function FAQAccordian(props: IProps) {
    const {  style, answer, question, index } = props
    const [active, setActive] = useState(false)
    const contentRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if(contentRef.current !== null){
            contentRef.current.style.maxHeight = active
                ? `${contentRef.current.scrollHeight}px`
                : "0px"
        }
    }, [contentRef, active])

    const toggleAccordion = () => {
        setActive(!active)
    }
    return (
        <>
            <div style={containerStyle}>
                <div>
                    <button
                        
                      
                        className={`questionStyle ${active}`}
                        onClick={toggleAccordion}
                    >
                        <div>
                            <div className="alignQuestion">
                                <span>
                                    {index+1}) {question}
                                </span>
                                <span><FaChevronDown
                                    style={
                                        active
                                            ? { ...chevron, ...rotateRight }
                                            : { ...chevron, ...rotateLeft }
                                    }
                                /></span>
                            </div>
                            <div
                                ref={contentRef}
                                className={active ? `answerStyle answer-divider divider` : `answerStyle`}
                            >
                                <p>{answer}</p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}



const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    height: "auto",
    background: "#e6e6e6",
    overflow: "visible",
}

const rotateRight: React.CSSProperties = {
    transform: "rotate(-180deg)",
    transition: "transform 0.4s ease",
}
const rotateLeft: React.CSSProperties = {
    transform: "rotate(0deg)",
    transition: "transform 0.4s ease",
}

const chevron: React.CSSProperties = {
    color: "black",
}





