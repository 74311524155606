// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import SDButton from "../ui/SDButton"
import {ReactComponent as DiscordLogo} from "../../assets/svgs/logo_discord.svg"
import {ReactComponent as MetaMaskLogo} from "../../assets/svgs/logo_metamask.svg"
import {ReactComponent as SDBadge} from "../../assets/svgs/logo_superdraft_badge_dark.svg"
import HeroImage from "../../assets/images/grassField.png"
import CountDownTimer from "../ui/CountDownTimer";
import { Dates } from "../../common/Constants";
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';

interface IProps {
    onClickDiscord:()=>void,
    onClickWallet:()=>void,
    onClickWhiteList:()=>void,
    style?: object,
    onRefresh:()=>void
}


export default function Block_DontMissOut(props: IProps) {
    const { style } = props

    return (
        <motion.div style={style }  className="mainWrapper lightContainer">
            
           <div className="innerWrapper column center">
               <div className="marginMedium innerBlock">
                    <div className="textBlock">
                            <div className="runningHead dark alignLeft">
                                <CountDownTimer targetDate={Dates.NFT_SPLASH_LAUNCH} onExpire={props.onRefresh}/>
                                </div>
                            <div className="heading dark marginSmall">
                                Don't miss out
                            </div>
                            <div className="bodyTxt dark marginMedium">
                                Get started today to get exclusive access to SuperDraft’s unique NFT collection, prizes, and more.
                            </div>
                            <div style={buttonGroup} >
                                <SDButton tint="#000" textColor="#fff" label="Get On The Whitelist"  onClick={props.onClickWhiteList}/>
                            </div>
                        </div>
                    <div>    
                    <div className="heroImage4">
                        <img src={HeroImage} style={{width:"100%", height:"auto"}} />
                    </div>
                        {/* <div className="videoBlock" /> */}
                    </div>
                </div>
                
                <div className="badgeGroup row bodyTxt dark">
                    <div className="badge column">
                        <MetaMaskLogo className="waxHero pointer" onClick={props.onClickWallet}/>
                        <span className="subHeadline strong">Create / Link a Wallet</span>
                       To purchase and keep track of your NFT's you'll need a wallet. We recommend MetaMask.
                        </div>
                        <div className="badge column">
                        <DiscordLogo className="atomicHero pointer" onClick={props.onClickDiscord}/>
                        <span className="subHeadline strong">Join our Discord</span>
                        Get access to all of the latest news and exclusive offers
                        </div>
                        <div className="badge column">
                        <SDBadge className="sdBadgeHero pointer" onClick={props.onClickWhiteList}/>
                        <span className="subHeadline strong">Get on the whitelist</span>
                        Be the first to access our NFT releases
                    </div>
                </div>
          
         
            </div>
        </motion.div>
    )
}

Block_DontMissOut.defaultProps = {
    tint: "#09F",
}

const column: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: 16,
}

const block1: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 16,
    width: "100%",
    maxWidth: "1400px",
}

const buttonGroup = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    gap:24,
    overflow: "hidden",
    width:"auto",

}


