import { Item } from "framer-motion/types/components/Reorder/Item";
import nflTeams from '../../assets/data/nflTeams.json';

export const Conferences = {
    AFC: "afc",
    NFC: "nfc"
}

export const Divisions = {
    NORTH: "north",
    SOUTH: "south",
    EAST: "east",
    WEST: "west"    
}

export interface ITeam {
    id:string;
    name:string;
    city:string;
    conference:string;
    division:string;
    primary:string,
    secondary:string
}

export function getRandomTeam():ITeam {
    return nflTeams[Math.floor(Math.random()*nflTeams.length)];
    // const teams:ITeam[] = nflTeams;
    // return teams[0];
}

