import React from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import {motion, AnimatePresence} from 'framer-motion'
import styled from "styled-components";

const backDrop = {
    visible: {opcacity:1},
    hidden: {opcacity:0},
}

interface IProps {
    closeFunc:()=>void
}

const OverLay = styled.div`
    background-color:rgba(0,0,0,.75);
    position:fixed;
    height:100%;
    width:100vw;
    top:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0px;
`
const ModalWrapper : React.FC<IProps>= ({closeFunc, children}) => {
    return (
        createPortal(<OverLay onClick={closeFunc}>
            <div style={{display:"flex"}} onClick={e => {e.stopPropagation(); console.log("stopProp")}}>
                {children}
            </div>
        </OverLay>, document.getElementById("modalRoot")!)
        
    );
}

export default ModalWrapper;