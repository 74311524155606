import React, { useState, useRef, useEffect } from "react";
import Footer from "../components/home/Footer";
import Navigation from "../components/home/Navigation";
import packVideoPath from '../assets/videos/Legendary_Template-1_2.mp4';
import ReactPlayer from 'react-player';
import openedPackImage from "../assets/images/grassField.png"

export interface IProps {}

const OpenPackPage: React.FunctionComponent<IProps> = props =>  {
  const videoPlayerRef = useRef(null);
  const videoIsShowing = useRef(true);
  const [isVideoPackPlaying, setIsVideoPackPlaying] = useState(false);
  const [isVideoPackComplete, setIsVideoPackComplete] = useState(false);
  console.log("OpenPackPage: " + isVideoPackPlaying);
  const percentRef = useRef(0);
  // if(percentRef.current < 1 || props.percent == 0) {
  //     percentRef.current = props.percent;
  //     videoIsShowing.current = percentRef.current >= 0;
  // }

  const onClickVideoPack = ()=>{
    console.log("videoPackClicked");
    setIsVideoPackPlaying(true);
  }
  
  const onVideoPackComplete = ()=>{
    console.log("videoPackCompleted");
    setIsVideoPackComplete(true);
  }

  return <div style={{
      backgroundColor: '#FFF'
    }}>
      <div>
        <Navigation
          onClickDiscord= {() => {}}
          onClickUser= {() => {}}
          onClickAbout= {() => {}}
          onClickFaqs= {() => {}}
          onClickWallet= {() => {}}
          style = {{}}
          />
      </div>
      <div style={content}>
        <div style={{display:"flex", width:"100%", height:"100%"}}>
          {!isVideoPackComplete && 
            (<ReactPlayer
              onClick={onClickVideoPack}
              onEnded={onVideoPackComplete}
              ref = {videoPlayerRef}
              // onReady= {setPlayHead}
              className='react-player fixed-bottom'
              url= {packVideoPath}
              width='100%'
              height='100%'
              loop = {false}
              playing = {isVideoPackPlaying}
              controls = {false}
            />
        )}
        {isVideoPackComplete && 
          (<div className="heroImage4">
                          <img src={openedPackImage} style={{width:"100%", height:"auto"}} />
                      </div>
          )}
          </div>
        </div>
      <hr style={{borderColor:"#FFF", width:"100%"}}/>
      <Footer></Footer>
  </div>
}


const content: React.CSSProperties = {
  width: "100%",
  height:400,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
}

export default OpenPackPage;