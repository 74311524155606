import { useState, useRef, useEffect } from "react"
import { motion } from "framer-motion"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import HeroImage from "../../assets/images/grassField.png"
import SDButton from "../ui/SDButton";
import { FaTimes } from "react-icons/fa"

interface IProps { 
    style?:object
    tint:string
    onClose:()=>void
}

export default function WhiteList(props: IProps) {
    const { tint, style } = props

    const [active, setActive] = useState(false);
    const focusDivRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     if(focusDivRef.current) {
    //         console.log("whitelist focus");
    //         focusDivRef.current.focus(); 
    //     } 
    // }, [focusDivRef]);

    return (
        <motion.div className="modal" style={style } >
        <div ref={focusDivRef} className=" whitelist">
            <div className="closeIcon pointer"><FaTimes style={{height:"24px", width:"24px"}} onClick={props.onClose} /></div>
            <div className="bodyTxt">SUPERDRAFT MATCH</div>
                <div className="heading">What is the Whitelist, and how do I get on it?</div>
              
                <div className="heroImage4">
                <img src={HeroImage} style={{width:"100%", height:"auto"}} />
                </div>
                <div className="sectionTitle strong">Section Title</div> 
                <div className="bodyTxt"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur pretium magna, sed tincidunt est iaculis eget. Suspendisse ligula sapien, gravida quis mi a, scelerisque auctor risus. Praesent sit amet metus lectus. Nam fringilla vitae mi vel interdum. Quis bibendum dolor posuere sit amet. Pellentesque ac dapibus sapien. Suspendisse aliquam rutrum massa, vitae rhoncus magna hendrerit ut. Mauris varius erat mauris, ut dapibus eros maximus vitae. Suspendisse pulvinar non metus ut volutpat.</p>

                <p>Sed dictum ligula quis ultrices volutpat. Quisque justo eros, vulputate eget interdum eu, elementum nec ipsum. Suspendisse auctor ultrices purus, ac ullamcorper odio ultrices sed. Quisque condimentum mattis leo. Etiam nisl purus, imperdiet a semper ut, volutpat posuere nisi. Quisque sed urna eleifend, posuere nulla ac, feugiat est. Integer dignissim imperdiet pulvinar.</p></div>

               

                <SDButton tint="#000" textColor="#fff" label="Join Our Discord" onClick={props.onClose}/>
            </div>
            
        </motion.div>
    )
}

WhiteList.defaultProps = {
    tint: "#09F",
}