import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import store, {selectStyle}from "./redux/store"
import {Provider} from 'react-redux'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import NftInventoryPage from './pages/NftInventoryPage';
import NftPacksPage from './pages/NftPacksPage';
import HowToPlayPage from './pages/HowToPlayPage';
import { Navigations } from './common/Constants';
import HelpPage from './pages/HelpPage';
import AboutPage from './pages/AboutPage';
import StatusPage from './pages/StatusPage';
import PressPage from './pages/PressPage';
import PartnersPage from './pages/PartnersPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import DiscordPage from './pages/DiscordPage';
import MarketPage from './pages/MarketPage';
import BlogPage from './pages/BlogPage';
import SplashPage from './pages/SplashPage';
import OpenPackPage from './pages/OpenPackPage';

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = props => {
   
    return (<Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={Navigations.SPLASH} element={<SplashPage/>} />
                        <Route path={Navigations.HOME} element={<HomePage/>} />
                        <Route path={Navigations.NFT_PACKS} element={<NftPacksPage/>} />
                        <Route path={Navigations.NFT_INVENTORY} element={<NftInventoryPage/>} />
                        <Route path={Navigations.HOW_TO_PLAY} element={<HowToPlayPage/>} />
                        <Route path={Navigations.HELP} element={<HelpPage/>} />
                        <Route path={Navigations.ABOUT} element={<AboutPage/>} />
                        <Route path={Navigations.STATUS} element={<StatusPage/>} />
                        <Route path={Navigations.PRESS} element={<PressPage/>} />
                        <Route path={Navigations.PARTNERS} element={<PartnersPage/>} />
                        <Route path={Navigations.PRIVACY} element={<PrivacyPage/>} />
                        <Route path={Navigations.TERMS} element={<TermsPage/>} />
                        <Route path={Navigations.DISCORD} element={<DiscordPage/>} />
                        <Route path={Navigations.MARKET} element={<MarketPage/>} />
                        <Route path={Navigations.BLOG} element={<BlogPage/>} />
                        <Route path={Navigations.OPEN_PACK} element={<OpenPackPage/>} />
                    </Routes>
                </BrowserRouter>
            </Provider>

    );
}

export default Application;