import useGeolocation from 'react-hook-geolocation'
import geoLocations from '../../assets/data/geoLocations.json'
import nflTeams from '../../assets/data/nflTeams.json'
import { getClosestGeoLocation } from '../../utils/GeoUtil'
import {ITeam} from '../../common/sports/nfl'
import {ILocation} from '../../common/sports/Locations'

interface IProps {
  team:ITeam,
  isRandomTeam:boolean
  lat:number,
  lon:number,
  style?:object;
}

export default function BlockGeoLoc(props: IProps) {

  return true//!geolocation.error
    ? (
      <div style={{display:"flex", flexDirection:"column"}}>
        <ul style={{flex:"1"}}>
          <li>Latitude:          {props.lat}</li>
          <li>Longitude:         {props.lon}</li>
          {/* <li>Location accuracy: {geolocation.accuracy}</li> */}
          <li>Closest Location: {props.team != null ? props.team.name : "none"}</li>
          <li>Closest Team: {props.team != null ? props.team.name : "NA"}</li>
          {props.isRandomTeam == true && <li>Team is Random</li>}
          <li>Id: {props.team != null ? props.team.id : "NA"}</li>
          <li>Conference: {props.team != null ? props.team.conference : "NA"}</li>
          <li>Division: {props.team != null ? props.team.division : "NA"}</li>
          <li>Primary: {props.team != null ? props.team.primary : "NA"}</li>
          <li style={{background:props.team?.primary, width:"25%"}}></li>
          <li>Secondary: {props.team != null ? props.team.secondary : "NA"}</li>
          <li style={{background:props.team?.secondary, width:"25%"}}></li>


            {/* <li>Locations</li> */}
            {/* {teams.map((iTeam, index, teams)=> {
              return <li key={index}>{index + 1 + ") " + iTeam.id + " : " + iTeam.city + " " + iTeam.name}</li> 
            })} */}
            {/* {locations.map((iLocation, index, locations)=> {
              return <li key={index}>{index + 1 + ") " + iLocation.name + " " + iLocation.nflTeams + " : " + iLocation.coordinates.map((iCoordinate, index, coordinates)=> iCoordinate.length > 0 ? "{"+ iCoordinate[0] + " : " + iCoordinate[1] +"} " : "{}")}</li> 
            })} */}
        </ul>
      </div>
    )
    : (
      <p>No geolocation, sorry.</p>
    )

}