// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import LogoAP from "../../assets/images/logo_ap.png"
import LogoCaesars from "../../assets/images/logo_caesars.png"
import LogoSI from "../../assets/images/logo_si.png"
import LogoSportico from "../../assets/images/logo_sportico.png"
import LogoYahoo from "../../assets/images/logo_yahoo.png"
import LogoDfs from "../../assets/svgs/dfs.svg"
import LogoIGaming from "../../assets/svgs/igaming.svg"
import LogoSportsbook from "../../assets/svgs/sportsbook.svg"


interface IProps {
    onClickSuperDraft:()=>void,
    style?: object
}


export default function Block_About(props: IProps) {
    const { style } = props

    return (
        <motion.div style={style}  className="mainWrapper blackContainer">
           <div className="innerWrapper column">
                <div className="subHeading light center alignCenter">Real fans prefer SuperDraft</div>
            <div className="discordRow">
                <div className="light bodyText alignCenter center">
                    <p className="marginMedium">SuperDraft has been in the sports tech industry since 2018 when SuperDraft Fantasy sports was launched.<br/>Since then, we <span className="winnerGreen">paid out over $40 Million</span> in prizes and have built three additional products.   </p>
                    <div className="logoGroup center marginLarge" >
                        <div className="coLogo"><img src={LogoDfs} className="logoDfs"/></div>
                        <div className="coLogo"><img src={LogoSportsbook} className="logoSportsbook"/></div>
                        <div className="coLogo"><img src={LogoIGaming} className="logoIGaming"/></div>
                    </div>
                    <div className="logoGroup center" >
                        <div className="logo"> <img src={LogoCaesars} className="logoCaesars"/></div>
                        <div className="logo"> <img src={LogoSI} className="logoSI"/></div>
                        <div className="logo"> <img src={LogoYahoo} className="logoYahoo"/></div>
                        <div className="logo"> <img src={LogoAP} className="logoAP"/></div>
                        <div className="logo"> <img src={LogoSportico} className="logoSportico"/></div>
                    </div>
                </div>
            </div>
         
            </div>
        </motion.div>
    )
}

Block_About.defaultProps = {
    tint: "#09F",
}

