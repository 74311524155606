import { useState } from "react"
import { motion } from "framer-motion"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';
import BoostScatter from "../../assets/images/boostScatter.png"


interface IProps { 
    style?:object
    tint:string
}

export default function BoostLineup(props: IProps) {
    const { tint, style } = props

    const [active, setActive] = useState(false)

    return (
        <motion.div style={style }  className="mainWrapper crackedDirt">
        <div className="innerWrapper column">
         <div style={column}>
               
                <div className="runningHead light">SuperGear is here</div>
                <div className="heading light">Boost Your Lineup</div>
                </div>
                <div style={{padding:"0px"}}>
                <img src={BoostScatter} style={{width:"100%", height:"auto"}} />
                </div>
                <div className="bodyTxt light">SuperGear Booster packs will be available for purchase in November 2022.</div>
            </div>
        </motion.div>
    )
}

BoostLineup.defaultProps = {
    tint: "#09F",
}

const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    height: "auto",
    background: "#ffffff",
    overflow: "visible",
}

const column: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: 16,
}



const headline2: React.CSSProperties = {
    width: "auto",
    height: "auto",
    whiteSpace: "pre",
    overflow: "visible",
    fontWeight: 900,
    fontFamily: '"Inter-Black", "Inter", sans-serif',
    color: "#000000",
    fontSize: "48px",
    letterSpacing: "0px",
    lineHeight: 1.2,
}

const block1: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 16,
    width: "100%",
    maxWidth: "1400px",
}
const block2: React.CSSProperties = {
    display: "flex",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "start",
    flexDirection: "row",
    gap: 32,
    width: "100%",
    maxWidth: "1400px",
}
