// @ts-nocheck

import { motion } from "framer-motion"
// import { Interface } from "readline"
import  '../../common/styles/scss/Typography.scss';
import  '../../common/styles/scss/SDStyles.scss';
import  '../../common/styles/scss/SDColors.scss';

interface IProps {
    style?: object
}


export default function Block_About(props: IProps) {
    const { style } = props

    return (
        <motion.div style={style}  className="mainWrapper lightContainer">
           <div className="innerWrapper column">
                <div className="dark alignCenter">
                        <div className="subHeading">2022 Timeline</div>
                        <p>Create lineups built to win by earning points based on players.<br/>Match-up against the house and other players to win NFTs, cash and prizes.</p>
                </div>
                <div className="timelineGroup center" >
                        <div className="timeBlock"><span className="strong">Q2</span>Mint Cards<span className="timeline"><span className="dot" /></span></div>
                        <div className="timeBlock"><span className="strong">Q2</span>Collect and Trade<span className="timeline"><span className="dot" /></span></div>
                        <div className="timeBlock"><span className="strong">Q3</span>Launch Game Mode<span className="timeline"><span className="dot" /></span></div>
                        <div className="timeBlock"><span className="strong">October</span>Card Release<span className="timeline"><span className="dot" /></span></div>
                        <div className="timeBlock"><span className="strong">December</span>Tournament<span className="timeline"><span className="dot" /></span></div>
                </div>
            </div>
        </motion.div>
    )
}

