import locations from '../assets/data/geoLocations.json'
import {ILocation} from '../common/sports/Locations'


export const getClosestGeoLocation = (lat:number, lon:number)=> {
    console.log("getClosestLocation: " + lat + " : " +lon);
    let closestLoc:ILocation = {id:"0",
    name:"",
    coordinates:[[]],
    nflTeams:[] };

    let closestDist = -1;
    locations.forEach( (location, index)=>{
        location.coordinates.forEach( (coord, i)=> {
            const dist = getDistanceFromLatLon(lat, lon, coord[0], coord[1]);
            // console.log("dist: " + dist + " : "+ location.name);
            if(closestDist === -1) {
                closestDist = dist;
                closestLoc = location;
            }
            else if(closestDist > dist) {
                closestDist = dist;
                closestLoc = location;
            }
        })
    })
    if(closestLoc != null) {
        console.log("closest loc: " + closestLoc.name + " : distance miles: "+ closestDist);
    }
    else {
        console.log("closest loc is null")
    }
    return closestLoc;
}

function getDistanceFromLatLon(lat1:number,lon1:number,lat2:number,lon2:number) {
    var R = 3950; // Radius of the earth in miles
    // var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }

  function getDistanceFromLatLon2(lat1:number,lon1:number,lat2:number,lon2:number) {
      console.log(lat1 + " : "+ lon1 + " --- " + lat2 + " : "+ lon2);
      const diffLat = lat2 - lat1;
      const diffLon = lon2 - lon1;
      console.log(diffLat + " : "+ diffLon);
    return Math.sqrt(Math.pow(lat2-lat1, 2) + Math.pow(lon2-lon1, 2)); 
  }
  
  function deg2rad(deg:number) {
    return deg * (Math.PI/180)
  }