import { useState } from "react"
import { motion } from "framer-motion"
import FAQAccordian from "../ui/FAQAccordian"
import faqsJson from "../../assets/data/faq.json"

interface IProps { 
    style?:object
    tint:string
}

interface IFaq {
    question:string,
    answer: string
}

export default function FAQs(props: IProps) {
    const { tint, style } = props
    const [active, setActive] = useState(false)
    const faqsList: IFaq[] = faqsJson.faqs; 

    return (
        <motion.div style={style}  className="mainWrapper lightContainer">
            <div className="innerWrapper column">
                <div className="subHeading marginMedium">Frequently Asked Questions</div>
                <div className="block2">
                    <div className="faqColumn">
                        {faqsList.map((faq, index) => 
                            (index <= Math.floor(faqsList.length * .5)) ? 
                            <FAQAccordian key={index} question={faq.question} answer={faq.answer} index={index}/> :
                            null
                        )} 
                    </div>
                    <div className="faqColumn">
                    {faqsList.map((faq, index) => 
                            (index > Math.floor(faqsList.length * .5)) ? 
                            <FAQAccordian key={index} question={faq.question} answer={faq.answer} index={index}/> :
                            null
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

FAQs.defaultProps = {
    tint: "#09F",
}



