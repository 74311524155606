import React from "react";
import WhiteList from "../../home/WhiteList";
import ModalWrapper from "./ModalWrapper";
import {ModalContainer, Header } from "./ModalPopup.styles";

interface IProps {
    onClose: ()=>void;
}

const Modal: React.FC<IProps> = (props) => {
    return (<ModalWrapper closeFunc={props.onClose}>
        <ModalContainer>
            {/* <WhiteList style = {{}} closeFunc={props.onOverlayClick}/> */}
            {props.children}
        </ModalContainer>
    </ModalWrapper>);
}

export default Modal;