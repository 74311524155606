import React from "react";
import Modal from "./modal/ModaL";
import { motion } from "framer-motion"
// import { ModalContainer, Header } from "./modal/ModalPopup.styles";
import styled from 'styled-components';
import { FaTimes } from "react-icons/fa"
import {ReactComponent as SDLogo} from "../../assets/svgs/sd_matchups.svg"

interface IProps {
    onClose: ()=>void;
    onClickDiscord: () => void;
    onClickAbout: () => void;
    onClickFaqs: () => void;
    onClickUser: () => void;
    onClickWallet: () => void;
    style?:object;
}


const NavModalMenu: React.FC<IProps> = (props) => {
    return (<Modal onClose={props.onClose}>
        {/* <ModalContainer> */}
            <motion.div className="navModalContainer" style={{ ...props.style }}>
            <div className="closeIcon pointer"><FaTimes style={{height:"24px", width:"24px"}} onClick={props.onClose} /></div>
                <ul className="navModalGroup">
                    <li>
                        <a onClick={() => props.onClickFaqs()}>
                            FAQs
                        </a>
                    </li>
                    <li>
                        <a onClick={() => props.onClickAbout()}>
                            About
                        </a>
                    </li>
                    <li>
                        <a onClick={() => props.onClickDiscord()}>
                            Discord
                        </a>
                    </li>
                    {/* <li>
                        <a onClick={() => props.onClickUser()}>
                            User Login
                        </a>
                    </li> */}
                    <li>
                        <a onClick={() => props.onClickWallet()}>
                            Wallet
                        </a>
                    </li>
                </ul>    
                    
            </motion.div>
    </Modal>);
}

const fontStyleNav: React.CSSProperties = {
    textDecoration: "none",
    fontWeight: 500,
    fontFamily: '"Inter-Medium", "Inter", sans-serif',
    color: "#333333",
    fontSize: "17px",
}

export default NavModalMenu;