import {configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit"
import { type } from "os";


interface IAppStyle {
    primaryColor:string
}

interface GeneralState {
    style:IAppStyle;
}
  
const initialState: GeneralState = {
    style: {primaryColor:"#FF0000"}
}
  
export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setPrimaryColor: (state, action: PayloadAction<string>) => {
        state.style.primaryColor = action.payload
        },
    },
})

export const { setPrimaryColor } = generalSlice.actions
export const selectStyle = (state:RootState) => state.general.style;

const store = configureStore({
    reducer:{
        general: generalSlice.reducer
    }
})

type RootState = ReturnType<typeof store.getState>
export default store;