import React from 'react';
import { useState, useEffect, useRef } from 'react';

interface IProps {
    targetDate:string,
    onExpire:()=>void
}

export default function CountDownTimer(props: IProps) {
    
    const [timeDelta, setTimeDelta] = useState(getTimeDelta(props.targetDate));

    useEffect(() => {
        const timer = setTimeout(() => {
            // console.log("timer");
            setTimeDelta(getTimeDelta(props.targetDate));
        }, 1000);
      });

    return <div style={timeGroup}>
                <div style={timeBlock}>{timeDelta.days + "d"}</div>
                <div style={timeBlock}>{timeDelta.hours + "h"}</div>
                <div style={timeBlock}>{timeDelta.mins + "m"}</div>
                <div style={timeBlock}>{timeDelta.secs + "s"}</div>
            </div>;
}

function getTimeDelta(targetDate:string):ITimeDelta {
    var countDownDate = new Date(targetDate).getTime();
    var now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    return {
        days: Math.max(0, Math.floor(distance / (1000 * 60 * 60 * 24))),
        hours: Math.max(0, Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))),
        mins: Math.max(0, Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))),
        secs: Math.max(0, Math.floor((distance % (1000 * 60)) / 1000))
    }
}

interface ITimeDelta {
    days: number,
    hours: number,
    mins: number, 
    secs: number
}

const timeGroup:React.CSSProperties ={
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    gap: 8,
    width: "100%",
    marginBottom:24
}

const timeBlock: React.CSSProperties = {
    display: "flex",
    backgroundColor:"#eee",
    color:"#333",
    height:56,
    width:56,
    textAlign:"center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    border:"1px solid #999",
    fontSize:18,
    borderRadius:2,
    fontWeight:700
   
}